.card {
    &[data-pint-1320="setToNone"] {
        display: flex;
        flex-direction: column;

        background-color: #fff;
        margin: 5px 0px;
        min-width: 240px;

        h2 {
            background: #e06939;
            border: #d0d0d0;

            font-size: 12pt;
            line-height: 34px;
            text-decoration: underline;
            text-align: center;
            margin: 0;

            a {
                color: #FFF;

                &::before {
                    content: '';
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    background-image: url(https://s3.amazonaws.com/assets.myfavsexcams.xxx/images/users-guide/user-guide-icon.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-bottom: -4px;
                    margin-right: 7px;
                }
            }
        }
    }
}