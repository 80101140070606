.container{
    margin: 0px 12px 12px 12px;
}
.aboveTheFold{
    overflow: scroll !important;
}
.container,
.container.categoryPage {
    height: 450px;
    padding: 15px 20px;
    overflow: hidden;
    background-color: #FFF;
    position: relative;

    .mascot {
        max-width: 205px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    h2, h3 {
        color: #000;
        margin: 0;
    }

    h3 {
        margin: 15px 0 5px 0;
    }

    .img img {    
        width: 100px;
        height: 100px;
    }


    p {
        font-family: 'arial';
        font-size: 11pt;
        color: #606060;
        line-height: 24px;
        text-align: justify;
        margin: 10px 0;

        a {
            color: #e06939;
        }
    }

    .list {
        list-style-type: none;
        display: block;
        margin: 0 0 0 20px;
        font-family: 'arial';
        font-size: 11pt;
        line-height: 24px;
        float: none;
        text-align: justify;
        padding: 0;
        padding-left: 10px;

        .listItem::before {
            content: "•";
            color: #e06939;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }

        .listItem {
            a {
                color: #e06939;
            }
        }
    }

    .visitButton {
        display: flex;
        justify-content: center;
        padding: 10px;
        width: 100%;
        font-size: 18px;
        color: #FFF;
        background-color: #e06939;
        border-radius: 6px;
        margin-top: 20px;
    
        &:hover {
            background: #3e3e3e;
        }
    }

    .extraContent {
        margin: 20px 0 0 0;
        width: 100%;
        border: 1px solid #d0d0d0;

        .heading {
            padding: 10px 0 10px 45px;
            width: 100%;
            color: #2877ae;
            font-weight: bold;
            background: url(https://assets.myfavsexcams.xxx/images/icon-external-links.svg) no-repeat 10px 8px #ebebeb;
            background-size: 25px 25px;
        }

        p {
            margin: 10px;
            font-size: 15px;
            line-height: 19px;
            color: #606060;
            text-align: justify; 
        }
    }

    @media (min-width: 400px) {
        .img img {    
            width: 240px;
            height: 240px;
        }       
    }

    @media (min-width: 550px) {
        margin-bottom: 20px;
        padding: 15px 20px;

        .visitButton {
            font-size: 24px;
            margin-top: 0px;
        }
    }
}

.container.categoryPage {
    margin: unset;
    @media (min-width: 550px) {
        height: 350px !important;
        overflow-y: scroll !important;
    }
}

.buttonsBox {
    background: #fff;
    padding-bottom: 20px;
    .readMoreFading {
      position: relative;
      margin-top: -160px;
      width: 100%;
      height: 160px;
      background: linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,1) 100%) !important;
    }
    .readMoreButton {
        font-size: 18px;
        color: #fff;
        background-color: #e06939;
        border-color: #d05929;
        border-radius: 6px;    
        margin: 0 20px 10px 20px;
        text-align: center;
        padding: 10px 0;
    }

    .visitButton {
        display: flex;
        justify-content: center;
        padding: 10px;
        font-size: 18px;
        color: #FFF;
        background-color: #e06939;
        border-radius: 6px;
        margin: 0 20px 0px 20px;

        &:hover {
            background: #3e3e3e;
        }

        @media (min-width: 550px) {
            font-size: 24px;
        }
    }

    .extraContent {
        margin: 20px 20px 0 20px;
        border: 1px solid #d0d0d0;

        .heading {
            padding: 10px 0 10px 45px;
            width: 100%;
            color: #2877ae;
            font-weight: bold;
            background: url(https://assets.myfavsexcams.xxx/images/icon-external-links.svg) no-repeat 10px 8px #ebebeb;
            background-size: 25px 25px;
        }

        p {
            margin: 10px;
            font-size: 15px;
            line-height: 19px;
            color: #606060;
            text-align: justify;
            
            a {
                color: #e06939;
            }
        }
    }
}