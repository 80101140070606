.categoryBanner{
  background: #000000;
  color:#F8F8F8;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px ;
  padding: 9px;
  margin: 10px;
  @media (max-width: 850px){
  margin: unset;
  }

}
.categories {
    &[data-pint-1320="setToNone"] {
      display:flex;
      @media (max-width: 550px) {
        width: 100%;
        flex-direction: column;
      }
  
  
      .firstWrapper{
        display: flex;
        flex-direction: row;
        @media (max-width: 1164px){
          width: 67%;
        }
        @media (max-width: 850px){
          flex-direction: column;
          width: 50%;
        }
        @media (max-width: 550px) {
          width: 100%;
        }
      }
      .secWrapper{
        display: flex;
        flex-direction: row;
        @media (max-width: 1164px){
          flex-direction: column;
          width: 33%;
        }
        @media (max-width: 850px){
          width: 50%;
        }
        @media (max-width: 550px) {
          width: 100%;  
        }
      }
  
  
      .firstCol{
        margin: 0 10px;
        max-width: 272px;
        width: 50%;
        @media (max-width: 1164px){
          max-width: unset;
        }
        @media (max-width: 850px) {
          width: 100%;
          margin: unset;
        }
  
      }
      .secondCol {
        margin: 0 10px;
        max-width: 272px;
        width: 50%;
        @media (max-width: 1164px){
          max-width: unset;
        }
        @media (max-width: 850px) {
          width: 100%;
          margin: unset;
        }
      }
      .thirdCol {
        margin: 0 10px;
        max-width: 272px;
        @media (max-width: 1164px) {
          max-width: unset;
        }
        @media (max-width: 550px) {
            margin: unset;
        }
  
      }
      .fourthCol{
        margin: 0 10px;
        max-width: 272px;
        @media (max-width: 1164px) {
          max-width: unset;
        }
        @media (max-width: 550px) {
            margin: unset;
        }
      }
  
  
      .showOne {
        display: block;
      }
  
      .showTwo,
      .showThree {
        display: none;
      }
  
      @media (min-width: 550px) {
  
        .showOne {
          display: none;
        }
  
        .showTwo {
          display: block;
        }
      }
  
      @media (min-width: 850px) {
  
  
        .showTwo {
          display: none;
        }
  
        .showThree {
          display: block;
        }
  
  
      }
  
    }

  }