.card {
    &[data-pint-1320="setToNone"] {
        display: flex;
        flex-direction: column;
        position: relative;

        background-color: #fff;
        margin: 5px 0px 10px 0px;
        min-width: 240px;

        .anchor {
            position: absolute;
        }

        h3 {
            color: #FFF;
            background: #000;
            border: #d0d0d0;

            font-size: 13pt;
            line-height: 34px;
            text-align: center;
            margin: 0;
        }

        .description {
            background-color: #f5f5f5;
            color: #606060;
            padding: 5px;
            font-size: 10pt;
            text-align: center;
        }

        .rankingList {
            max-height: 175px;
            overflow-y: scroll;

            .rankingItem,
            .rankingItem.trending {
                display: flex;

                .tooltipContainer {
                    position: relative;
                    width: 100%;
                    display: flex;
                    margin: 5px 0px;
                    padding-left: 5px;

                    .rank {
                        color: #cbcbcb;
                        font-size: 9pt;
                        font-family: 'Arial';
                        margin: 0px 15px 0 8px;
                        display: flex;
                        align-items: center;
                        width: 10px;
                    }

                    .site {
                        color: #303030;
                        display: flex;
                        align-items: center;

                        .icon {
                            background-image: url(https://assets.myfavsexcams.xxx/images/site-icons.png);
                            width: 16px;
                            height: 16px;
                            margin: 2px 10px 0 0;
                        }
                    }

                    .flagIcon {
                        display: flex;
                        align-items: center;
                        margin-left: 8px;

                        svg {
                            width: 14px;
                            height: 11px;
                        }
                    }
                    .hidden {
                      visibility: hidden;
                    }
                    .visible {
                      visibility: visible;
                    }
                    .tooltip {
                        color: #FFF;
                        background: #e06939;
                        position: absolute;
                        font-size: 10pt;
                        text-align: center;                    
                        border-radius: 5px 5px;
                        width: 100%;
                        padding: 2px 0;

                        &::after {
                            border-bottom: 5px solid transparent;
                            border-left: 5px solid #e06939;
                            border-top: 5px solid transparent;
                            content: "";
                            height: 0;
                            position: absolute;
                            right: -5px;
                            top: 4px;
                            width: 0;
                        }
                    }      
                }         

                .reviewEn,
                .review {
                    display: flex;
                    flex-direction: row;
                    color: #303030;
                    margin-left: 15px;
                    
                    .iconReview {
                        display: none;
                        align-items: center;
                        margin-right: 8px;

                        svg {
                            width: 29px;
                            height: 28px;
                        }
                    }
                    .iconTrending {
                        margin-top: 6px;
                        svg {
                            width: 40px;
                            height: 17px;
                        }                    
                    }
                    .iconNew {
                        svg {
                            width: 30px;
                            height: 30px;
                        }                    
                    }
                }

                &:hover {
                    background-color: #f7ded3;

                    .reviewEn {
                        .iconReview {
                            display: flex;
                        }
                        .iconTrending {
                            display: none;
                        }
                        .iconNew {
                            display: none;
                        }
                    }
                }
            }
            .rankingItem.trending:nth-child(2n+2) {
                background-color: rgba(50,180,60,0.05);
            }
        }

        @media (min-width: 550px) {
            .rankingList {
                max-height: 100%;
            }
        }

        @media (min-width: 1167px) {
            .rankingList {
                max-height: 350px;
            }
        }
    }    
}